import { MapContainer, ImageOverlay, Marker, Tooltip } from 'react-leaflet';
import { useEffect, useState, useCallback } from 'react';
import L from "leaflet";
import 'leaflet/dist/leaflet.css';
import React from 'react';
// import data from "../Source/MapData.json";
import { MBP_Small, MBP_Large, MBP_Admin, Players } from '../Source/convert';
import { useNavigate, useParams } from 'react-router-dom';
import { SelectAuth } from "../redux/authSlice";
import { useSelector } from 'react-redux';
import io from 'socket.io-client';
import { notification } from 'antd';

// const socket = io('http://localhost:7060');
const socket = io('https://massivebaseprotection.com');
// const API_URL ='http://localhost:7060/api';
const API_URL ='https://massivebaseprotection.com/api';

const ShareMap = () => {
  const navigate = useNavigate();
  const UserData = useSelector(SelectAuth);
  const [mbp_Small, setMBP_Small] = useState([]);
  const [mbp_Large, setMBP_Large] = useState([]);
  const [mbp_Admin, setMBP_Admin] = useState([]);
  const [players, setPlayers] = useState([]);
  const [data, setData] = useState({});                  //current map data
  const { id } = useParams();   //current map ID

  const handleSocketData = useCallback((data) => {
    console.log(data);
  }, []);

  useEffect(() => {
    
  }, [handleSocketData, navigate, UserData]);
  
  useEffect(() => {    
    if (Object.keys(data).length !== 0) {    
      setMBP_Small(MBP_Small(data));
      setMBP_Large(MBP_Large(data));
      setMBP_Admin(MBP_Admin(data));
      setPlayers(Players(data));
    } else if (!mbp_Small && !mbp_Large && !mbp_Admin && !players) {
      notification.warning({
        message: "Server communication error",
        description: "A problem occurred while communicating with the server."
      })
    }
    
  }, [data, mbp_Small, mbp_Large, mbp_Admin, players]);

  useEffect(() => {
    socket.emit('current_map', id);

    const handleMapData = (data) => {
      data && setData(data.mapData);
    };
    
    socket.on('mapData', handleMapData);

    return () => {
        socket.off('mapData', handleMapData);
    };
  }, [data, id, UserData]);  // Add dependencies if these might change
  
  return (
    <div>
      <div className='absolute top-0 left-1/2 -translate-x-1/2 bg-[#00000080] text-white py-3 px-10 rounded-bl-md rounded-br-md shadow-md  shadow-blue-400 z-[1001]'>
        Server Time: {data.body && data.body.serverclock}
      </div>
      {
        data && Object.keys(data).length !== 0 &&
        <MapContainer center={[50, 50]} zoom={3} minZoom={3} maxZoom={6} className='w-full h-screen' crs={L.CRS.Simple}>
          <ImageOverlay
            url={`${API_URL}/maps/${data.body.map}`}
            bounds={[[0, 0], [100, 100]]}
          />
          {mbp_Small && mbp_Small.map((marker, index) => (
            <Marker 
              key={index}
              position={[Math.abs(marker.y_pos-100), marker.x_pos]}
              icon={L.divIcon({
                className: 'custom-div-icon',
                html: `
                  <div class="custom-marker-wrapper">
                    <img src="/mbp_small.png" class="custom-marker-icon"/>
                    <div class="custom-marker-icon-overlay">
                      <i class="fa fa-home text-white text-[10px]"></i>
                    </div>
                  </div>`,
                iconSize: [32, 37],
                iconAnchor: [16, 37],
                shadowUrl: '/markers-shadow.png',
                shadowSize: [37, 37],
                shadowAnchor: [0, 37]
              })}
            >
              <Tooltip direction='top' offset={[0, -25]}>
                <div className='flex flex-col gap-1'>
                  <div className='text-center'>Mbp-Small : {marker.tribename}</div>
                  <div>X:{parseInt(marker.x_ue4)}, Y:{parseInt(marker.y_ue4)}, Z:{parseInt(marker.z_ue4)}</div>
                </div>
              </Tooltip>
            </Marker>
          ))}
          {mbp_Large && mbp_Large.map((marker, index) => (
            <Marker key={index}
              position={[Math.abs(marker.y_pos-100), marker.x_pos]}
              icon={L.divIcon({
                className: 'custom-div-icon',
                html: `
                  <div class="custom-marker-wrapper">
                    <img src="/mbp_large.png" class="custom-marker-icon"/>
                    <div class="custom-marker-icon-overlay">
                      <i class="fa ${marker.type === "mbp_admin" ? "fa-user" : "fa-home" } text-white text-[10px]"></i>
                    </div>
                  </div>`,
                iconSize: [32, 37],
                iconAnchor: [16, 37],
                shadowUrl: '/markers-shadow.png',
                shadowSize: [37, 37],
                shadowAnchor: [0, 37]
              })}
            >
              <Tooltip direction='top' offset={[0, -25]}>
                <div className='flex flex-col gap-1'>
                  <div className='text-center'>Mbp-Large : {marker.tribename}</div>
                  <div>X:{parseInt(marker.x_ue4)}, Y:{parseInt(marker.y_ue4)}, Z:{parseInt(marker.z_ue4)}</div>
                </div>
              </Tooltip>
            </Marker>
          ))}
          {mbp_Admin && mbp_Admin.map((marker, index) => (
            <Marker key={index}
              position={[Math.abs(marker.y_pos-100), marker.x_pos]}
              icon={L.divIcon({
                className: 'custom-div-icon',
                html: `
                  <div class="custom-marker-wrapper">
                    <img src="/mbp_admin.png" class="custom-marker-icon"/>
                    <div class="custom-marker-icon-overlay">
                      <i class="fa ${marker.type === "mbp_admin" ? "fa-user" : "fa-home" } text-white text-[10px]"></i>
                    </div>
                  </div>`,
                iconSize: [32, 37],
                iconAnchor: [16, 37],
                shadowUrl: '/markers-shadow.png',
                shadowSize: [37, 37],
                shadowAnchor: [0, 37]
              })}
            >
              <Tooltip direction='top' offset={[0, -25]}>
                <div className='flex flex-col gap-1'>
                  <div className='text-center'>Mbp-Admin : {marker.tribename}</div>
                  <div>X:{parseInt(marker.x_pos)}, Y:{parseInt(marker.y_pos)}</div>
                </div>
              </Tooltip>
            </Marker>
          ))}
          {players && players.map((marker, index) => (
            <Marker key={index}
              position={[Math.abs(marker.y_pos-100), marker.x_pos]}
              icon={L.divIcon({
                className: 'custom-div-icon',
                html: `
                  <div class="custom-marker-wrapper">
                    <img src="/players.png" class="custom-marker-icon"/>
                    <div class="custom-marker-icon-overlay">
                      <i class="fa fa-user text-white text-[10px]"></i>
                    </div>
                  </div>`,
                iconSize: [32, 37],
                iconAnchor: [16, 37],
                shadowUrl: '/markers-shadow.png',
                shadowSize: [37, 37],
                shadowAnchor: [0, 37]
              })}
            >
              <Tooltip direction='top' offset={[0, -25]}>
                <div className='flex flex-col gap-1'>
                  <div className='text-center'>{marker.playername} : {marker.tribename}</div>
                  <div>X:{parseInt(marker.x_pos)}, Y:{parseInt(marker.y_pos)}</div>
                </div>
              </Tooltip>
            </Marker>
          ))}
        </MapContainer>
      }
    </div>
  );
};

export default ShareMap;
