import { Button } from "antd";
import { Link } from "react-router-dom";


const Header = () => {
    
    return(
        <div className="bg-gray-500 text-center text-white py-1 flex justify-center items-center">
            Dive into the vast world of Ark today!
            <Link to={"https://discord.com/invite/W8TDWnEVeD"} className="mx-10">
                <Button type="primary">Join now</Button>
            </Link>
        </div>
    )
};

export default Header;