
import { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "../assets/logo.png"
import { notification, Popover } from 'antd';
import { signin } from '../services/authService';
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [forget, setForget] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const temp = await signin(email, password);
            temp === "manager" ? 
                navigate('/manager')    
                :
                navigate('/map')
        } catch (err) {
            console.log(err);
                notification.error({
                    message: 'Login Failed',
                    description: `${err.response.data && err.response.data.message ? err.response.data.message : "An unexpected error occurred. Please try again." }`,
                });
        }
    };

    const ForgetSubmit = () => {
        setForget(!forget);
    }
    
    return(
        <main className="flex min-h-screen flex-row items-center justify-center bg-[url(./assets/bg2.jpg)] bg-cover bg-no-repeat">
            <div className="xl:w-1/3 w-2/3 max-lg:w-[95%] bg-gradient-to-r from-[#cd37f6c5] via-[#818fd3c5] to-[#3adec5] border-4 shadow-custimizepink border-[#ff00f7e3] flex flex-col items-center justify-center rounded-lg px-10 py-20 max-lg:px-5 max-lg:py-10">
                <img src={logo} alt="" className=" w-1/4 my-5" />
                <form onSubmit={handleSubmit} className="space-y-6 w-3/4 mx-auto">
                    <div className='py-2'>
                        <div className="relative h-11 w-full min-w-[200px]">
                            <input placeholder="input email" onChange={e => setEmail(e.target.value)} value={email} required
                                className="placeholder-slate-200 pl-2 text-white peer h-full w-full border-b border-indigo-100 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-indigo-600 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100" />
                            <label
                            className="after:content[''] pointer-events-none absolute left-0  -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[13px] font-semibold leading-tight text-white transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-indigo-600 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-indigo-600 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-white peer-focus:after:scale-x-100 peer-focus:after:border-indigo-900 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-indigo-100">
                            Email
                            </label>
                        </div>
                    </div>

                    <div className='py-2'>
                        <div className="relative h-11 w-full min-w-[200px]">
                            <input placeholder="input password" onChange={e => setPassword(e.target.value)} value={password} required type='password'
                                className="placeholder-slate-200 pl-2 text-white peer h-full w-full border-b border-indigo-100 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-indigo-600 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100" />
                            <label
                                className="after:content[''] pointer-events-none absolute left-0  -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[13px] font-semibold leading-tight text-white transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-indigo-600 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-indigo-600 peer-focus:text-[11px] peer-focus:text-white peer-focus:leading-tight peer-focus:after:scale-x-100 peer-focus:after:border-indigo-900 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-indigo-100">
                            Password
                            </label>
                        </div>
                        <div className='pt-5 flex items-center justify-between'>
                            <div className="mb-[0.125rem] block min-h-[1.5rem] ps-[1.5rem]">
                                <input
                                    className="relative float-left -ms-[1.5rem] me-[6px] mt-[0.15rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-secondary-500 outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-checkbox before:shadow-transparent before:content-[''] checked:border-primary checked:bg-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:ms-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-black/60 focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-black/60 focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:before:shadow-checkbox checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px checked:focus:after:ms-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent rtl:float-right dark:border-neutral-400 dark:checked:border-primary dark:checked:bg-primary"
                                    type="checkbox"
                                    value=""
                                    id="exampleCheck2" />
                                <label
                                    className="inline-block ps-[0.15rem] hover:cursor-pointer text-white sm:text-base text-xs text-ellipsis" htmlFor="exampleCheck2">
                                    Remember me
                                </label>
                            </div>
                            <Popover
                                content={
                                    <div className='text-center'>
                                        <span onClick={() => setForget(!forget)}>cancel</span>
                                        <span onClick={ForgetSubmit}>Submit</span>
                                    </div>
                                }
                                title="Would you like to send it to manager?"
                                trigger="click"
                                open={forget}
                                onOpenChange={() => setForget(!forget)}
                                >
                                    <span className="text-blue-800 font-semibold cursor-pointer hover:text-white active:text-blue-600 transition-all duration-75 sm:text-base text-xs text-ellipsis">
                                        Forget Password?
                                    </span>
                            </Popover>
                        </div>
                    </div>

                    <div className='flex justify-center sm:scale-100 scale-75'>
                        <button className="hover:brightness-110 hover:animate-pulse font-bold py-3 px-6 rounded-full bg-[#cd37f6c5] shadow-lg shadow-indigo-500/50 text-white" type='submit'>
                            SignIn
                        </button>
                    </div>
                </form>
                
                <div className="pt-10 pb-3 text-[15px] text-white">
                    {"Don't have an account?"}  <Link to={"/signup"} className="text-yellow-400 hover:text-yellow-200">Sign up</Link>
                </div>
            </div>
        </main>
    )
}

export default SignIn;