
import { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "../assets/logo.png";
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { signup } from '../services/authService';

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (confirm === password) {
            try {
                await signup(email, password);
                navigate('/signin');
            } catch (err) {
                notification.error({
                    description: 'Invalid email or password.'
                });
            }
        } else {
            notification.error({
                description: "Please confirm password!"
            });
        }
    };

    return(
        <main className="flex min-h-screen flex-row items-center justify-center bg-[url(./assets/bg1.jpg)] bg-cover bg-no-repeat">
            <div className="xl:w-1/3 w-2/3 max-lg:w-[95%] bg-gradient-to-r from-[#cd37f6c5] via-[#818fd3c5] to-[#3adec5] border-4 shadow-custimizepink border-[#ff00f7e3] flex flex-col items-center justify-center rounded-lg px-10 py-20 max-lg:px-5 max-lg:py-10">
                <img src={logo} alt="" className=" w-1/4 my-5" />
                <form onSubmit={handleSubmit} className="space-y-6 w-3/4 mx-auto">
                    <div className='py-2'>
                        <div className="relative h-11 w-full min-w-[200px]">
                            <input placeholder="input email" onChange={e => setEmail(e.target.value)}
                                className="placeholder-slate-200 text-white pl-2 peer h-full w-full border-b border-indigo-100 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-indigo-600 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100" />
                            <label
                                className="after:content[''] pointer-events-none absolute left-0  -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[13px] font-semibold leading-tight text-white transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-indigo-600 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-indigo-600 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-white peer-focus:after:scale-x-100 peer-focus:after:border-indigo-900 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-indigo-100">
                            Email
                            </label>
                        </div>
                    </div>

                    <div className='py-2'>
                        <div className="relative h-11 w-full min-w-[200px]">
                            <input placeholder="input password" onChange={e => setPassword(e.target.value)} type='password'
                                className="placeholder-slate-200 text-white pl-2 peer h-full w-full border-b border-indigo-100 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-indigo-600 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100" />
                            <label
                                className="after:content[''] pointer-events-none absolute left-0  -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[13px] font-semibold leading-tight text-white transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-indigo-600 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-indigo-600 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-white peer-focus:after:scale-x-100 peer-focus:after:border-indigo-900 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-indigo-100">
                            Password
                            </label>
                        </div>
                    </div>

                    <div className='py-2'>
                        <div className="relative h-11 w-full min-w-[200px]">
                            <input placeholder="confirm password" onChange={e => setConfirm(e.target.value)} type='password'
                                className="placeholder-slate-200 text-white pl-2 peer h-full w-full border-b border-indigo-100 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-indigo-600 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50 placeholder:opacity-0 focus:placeholder:opacity-100" />
                                <label
                                className="after:content[''] pointer-events-none absolute left-0  -top-1.5 flex h-full w-full select-none !overflow-visible truncate text-[13px] font-semibold leading-tight text-white transition-all after:absolute after:-bottom-1.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-indigo-600 after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-indigo-600 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-white peer-focus:after:scale-x-100 peer-focus:after:border-indigo-900 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-indigo-100">
                            Confirm
                            </label>
                        </div>
                    </div>

                    <div className='flex justify-center'>
                        <button className="hover:brightness-110 hover:animate-pulse font-bold py-3 px-6 rounded-full bg-[#cd37f6c5] shadow-lg shadow-indigo-500/50 text-white" type='submit'>
                            SignUp
                        </button>
                    </div>
                </form>
                
                <div className="pt-10 pb-3 text-white">
                    {"Already have an account?"}  <Link to={"/signin"} className="text-yellow-400 hover:text-yellow-200">Sign In</Link>
                </div>
            </div>
        </main>
    )
}

export default SignUp;