const LoadMap = () => {
    return(
        <div className="fixed top-0 left-0 w-full h-screen flex items-center justify-center bg-[#212121]">
            <div class="loader_map">
                <span></span>
            </div>
        </div>
    )
}

export default LoadMap;