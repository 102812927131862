import Header from "../component/Header";

const Help = () => {
    return(
        <>
            <Header />
            <div className="w-full h-screen bg-[url(./assets/pagebackground2.jpg)] bg-contain bg-no-repeat bg-center bg-[#555]"></div>
        </>
    )
}

export default Help;