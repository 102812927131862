import { MapContainer, ImageOverlay, Marker, Tooltip } from 'react-leaflet';
import { useEffect, useState, useCallback } from 'react';
import L from "leaflet";
import 'leaflet/dist/leaflet.css';
import React from 'react';
import { MBP_Small, MBP_Large, MBP_Admin, Players } from '../Source/convert';
import { useNavigate } from 'react-router-dom';
import { SelectAuth } from "../redux/authSlice";
import { useSelector } from 'react-redux';
import { Button, Checkbox, Drawer, Empty, Image, Modal, notification, QRCode } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import io from 'socket.io-client';
import Loader from '../component/Loadingmap';
import "../map.css"
import logo from "../assets/logo.png"
import LoadMap from '../component/Loadingmap';
import { DeleteOutlined } from '@ant-design/icons';
import { deleteMapData } from '../services/manageService';
const { confirm } = Modal;

// const socket = io('http://localhost:7060');
const socket = io('https://massivebaseprotection.com');
// const API_URL ='http://localhost:7060/api';
const API_URL ='https://massivebaseprotection.com/api';

const Map = () => {
  const navigate = useNavigate();
  const UserData = useSelector(SelectAuth);
  const [mbp_Small, setMBP_Small] = useState([]);
  const [mbp_Large, setMBP_Large] = useState([]);
  const [mbp_Admin, setMBP_Admin] = useState([]);
  const [players, setPlayers] = useState([]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});                  //current map data
  const [currentMap, setCurrentMap] = useState("");      //current map ID
  const [currentMapLoading, setCurrentMapLoading] = useState(false);
  const [MyMaps, setMyMaps] = useState([]);      //current map ID
  const [MyMapLoading, setMyMapLoading] = useState(true);
  const [share, setShare] = useState(false);

  const showLoading = () => {
    setOpen(true);
  };

  const handleSocketData = useCallback((data) => {
    console.log(data);
  }, []);

  useEffect(() => {
    if (UserData && Object.keys(UserData).length === 0) {
      navigate('/');      
    }
    
  }, [handleSocketData, navigate, UserData]);
  
  useEffect(() => {    
    if (Object.keys(data).length !== 0) {    
      setMBP_Small(MBP_Small(data));
      setMBP_Large(MBP_Large(data));
      setMBP_Admin(MBP_Admin(data));
      setPlayers(Players(data));
    } else if (!mbp_Small && !mbp_Large && !mbp_Admin && !players) {
      notification.warning({
        message: "Server communication error",
        description: "A problem occurred while communicating with the server."
      })
    }
    console.log(data);
  }, [data, mbp_Small, mbp_Large, mbp_Admin, players]);

  useEffect(() => {
    if (!UserData) return;
    
    socket.emit('current_map', currentMap);
  
    const handleMapData = (data) => {
      if (data) setData(data.mapData);
      setCurrentMapLoading(false);
    };
  
    socket.on('mapData', handleMapData);
    socket.emit('get_my_maps', UserData.id);
  
    const handleMyMaps = (data) => {
      if (data) setMyMaps(data);
      setMyMapLoading(false);
    };
  
    socket.on('mymaps', handleMyMaps);
  
    return () => {
      socket.off('mapData', handleMapData);
      socket.off('mymaps', handleMyMaps);
    };
  }, [currentMap, UserData]);  // Only run when currentMap or UserData change
  

  const selectCurrentMap = (id) => {
    setCurrentMap(id); 
    setOpen(false);
  }

  const ConfirmModal = (id) => {
    confirm({
      title: 'Delete MapData',
      content: "Are you sure you want to delete it?",
      onOk(){
        deleteMapData(id)
      }
    });
  }
  
  return (
    <div className=' min-h-screen'>
      <div className='absolute top-0 left-1/2 -translate-x-1/2 bg-[#00000080] text-white md:py-2 md:px-5 rounded-bl-md rounded-br-md shadow-md shadow-blue-400 z-[1001] flex items-center justify-center px-1 py-1'>
        <span className='text-nowrap'>
          {data.body ? "Server Time: " + data.body.serverclock : "Please select the map!"}
        </span>
        <Button type="primary" onClick={showLoading} className='ml-5'>
          Maps
        </Button>
      </div>
      {
        currentMap &&
        <div className='flex justify-center items-center absolute sm:bottom-5 bottom-10 left-1/2 -translate-x-1/2 z-[1001] text-nowrap'>
          <button
            className="scale-75 font-sans flex justify-center gap-2 items-center mx-auto shadow-xl text-lg bg-gray-50 backdrop-blur-md lg:font-semibold isolation-auto border-gray-50 before:absolute before:w-full before:transition-all before:duration-700 before:hover:w-full before:-left-full before:hover:left-0 before:rounded-full before:bg-blue-600 hover:text-gray-50 before:-z-10 before:aspect-square before:hover:scale-150 before:hover:duration-700 relative z-10 px-3 py-1 overflow-hidden border-2 rounded-full group"
            onClick={() => setShare(!share)}
          >
            Share with a friend
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 19"
              className="w-8 h-8 justify-end group-hover:rotate-90 group-hover:bg-gray-50 text-gray-50 ease-linear duration-300 rounded-full border border-gray-700 group-hover:border-none p-2 rotate-45"
            >
              <path
                className="fill-gray-800 group-hover:fill-gray-800"
                d="M7 18C7 18.5523 7.44772 19 8 19C8.55228 19 9 18.5523 9 18H7ZM8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893L0.928932 6.65685C0.538408 7.04738 0.538408 7.68054 0.928932 8.07107C1.31946 8.46159 1.95262 8.46159 2.34315 8.07107L8 2.41421L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.70711 0.292893ZM9 18L9 1H7L7 18H9Z"
              />
            </svg>
          </button>
        </div>
      }
      <Drawer
        closable
        destroyOnClose
        title={<p>My Maps</p>}
        placement="right"
        open={open}
        onClose={() => setOpen(false)}
        loading={MyMapLoading}
      >
        {
          MyMaps.length !== 0 ? MyMaps.map((map, index) => (
            <div>
              <div key={index} className="cursor-pointer overflow-hidden relative transition-all duration-500 hover:translate-y-2 w-72 bg-neutral-50 rounded-lg shadow-xl flex flex-row items-center justify-evenly gap-2 p-2 before:absolute before:w-full hover:before:top-0 before:duration-500 before:-top-1 before:h-1 before:bg-purple-200 mx-auto">
                <div>
                  <div className="font-bold bg-slate-300 rounded-sm p-2 hover:bg-slate-400 transition" onClick={() => selectCurrentMap(map._id)}>
                    {map.mapData.body.map}
                    <Checkbox checked= { currentMap === map._id ? true : false } className='float-right ' />
                  </div>
                  <Image
                    className="w-full"
                    src={`${API_URL}/maps/${map.mapData.body.map}`}
                  />
                  <div className='py-1'>
                    <Button icon={<DeleteOutlined />} type='primary' danger block onClick={() => { ConfirmModal(map._id) }} />
                  </div>
                </div>
              </div>
            </div>
          ))
          :
          <div className='text-center'>
            <Empty />
            <span className='py-2'>Your map data has not been registered yet.</span>
          </div>
        }
      </Drawer>

      {
        data && Object.keys(data).length !== 0 ?
        <MapContainer center={[50, 50]} zoom={3} minZoom={3} maxZoom={6} className='w-full h-screen' crs={L.CRS.Simple}>
          <ImageOverlay
            url={`${API_URL}/maps/${data.body.map}`}
            bounds={[[0, 0], [100, 100]]}
          />
          {mbp_Small && mbp_Small.map((marker, index) => (
            <Marker 
              key={index}
              position={[Math.abs(marker.y_pos-100), marker.x_pos]}
              icon={L.divIcon({
                className: 'custom-div-icon',
                html: `
                  <div class="custom-marker-wrapper">
                    <img src="/mbp_small.png" class="custom-marker-icon"/>
                    <div class="custom-marker-icon-overlay">
                      <i class="fa fa-home text-white text-[10px]"></i>
                    </div>
                  </div>`,
                iconSize: [32, 37],
                iconAnchor: [16, 37],
                shadowUrl: '/markers-shadow.png',
                shadowSize: [37, 37],
                shadowAnchor: [0, 37]
              })}
            >
              <Tooltip direction='top' offset={[0, -25]}>
                <div className='flex flex-col gap-1'>
                  <div className='text-center'>Mbp-Small : {marker.tribename}</div>
                  <div>X:{parseInt(marker.x_ue4)}, Y:{parseInt(marker.y_ue4)}, Z:{parseInt(marker.z_ue4)}</div>
                </div>
              </Tooltip>
            </Marker>
          ))}
          {mbp_Large && mbp_Large.map((marker, index) => (
            <Marker key={index}
              position={[Math.abs(marker.y_pos-100), marker.x_pos]}
              icon={L.divIcon({
                className: 'custom-div-icon',
                html: `
                  <div class="custom-marker-wrapper">
                    <img src="/mbp_large.png" class="custom-marker-icon"/>
                    <div class="custom-marker-icon-overlay">
                      <i class="fa ${marker.type === "mbp_admin" ? "fa-user" : "fa-home" } text-white text-[10px]"></i>
                    </div>
                  </div>`,
                iconSize: [32, 37],
                iconAnchor: [16, 37],
                shadowUrl: '/markers-shadow.png',
                shadowSize: [37, 37],
                shadowAnchor: [0, 37]
              })}
            >
              <Tooltip direction='top' offset={[0, -25]}>
                <div className='flex flex-col gap-1'>
                  <div className='text-center'>Mbp-Large : {marker.tribename}</div>
                  <div>X:{parseInt(marker.x_ue4)}, Y:{parseInt(marker.y_ue4)}, Z:{parseInt(marker.z_ue4)}</div>
                </div>
              </Tooltip>
            </Marker>
          ))}
          {mbp_Admin && mbp_Admin.map((marker, index) => (
            <Marker key={index}
              position={[Math.abs(marker.y_pos-100), marker.x_pos]}
              icon={L.divIcon({
                className: 'custom-div-icon',
                html: `
                  <div class="custom-marker-wrapper">
                    <img src="/mbp_admin.png" class="custom-marker-icon"/>
                    <div class="custom-marker-icon-overlay">
                      <i class="fa ${marker.type === "mbp_admin" ? "fa-user" : "fa-home" } text-white text-[10px]"></i>
                    </div>
                  </div>`,
                iconSize: [32, 37],
                iconAnchor: [16, 37],
                shadowUrl: '/markers-shadow.png',
                shadowSize: [37, 37],
                shadowAnchor: [0, 37]
              })}
            >
              <Tooltip direction='top' offset={[0, -25]}>
                <div className='flex flex-col gap-1'>
                  <div className='text-center'>Mbp-Admin : {marker.tribename}</div>
                  <div>X:{parseInt(marker.x_ue4)}, Y:{parseInt(marker.y_ue4)}, Z:{parseInt(marker.z_ue4)}</div>
                </div>
              </Tooltip>
            </Marker>
          ))}
          {players && players.map((marker, index) => (
            <Marker key={index}
              position={[Math.abs(marker.y_pos-100), marker.x_pos]}
              icon={L.divIcon({
                className: 'custom-div-icon',
                html: `
                  <div class="custom-marker-wrapper">
                    <img src="/players.png" class="custom-marker-icon"/>
                    <div class="custom-marker-icon-overlay">
                      <i class="fa fa-user text-white text-[10px]"></i>
                    </div>
                  </div>`,
                iconSize: [32, 37],
                iconAnchor: [16, 37],
                shadowUrl: '/markers-shadow.png',
                shadowSize: [37, 37],
                shadowAnchor: [0, 37]
              })}
            >
              <Tooltip direction='top' offset={[0, -25]}>
                <div className='flex flex-col gap-1'>
                  <div className='text-center'>{marker.playername} : {marker.tribename}</div>
                  <div>X:{parseInt(marker.x_pos)}, Y:{parseInt(marker.y_pos)}</div>
                </div>
              </Tooltip>
            </Marker>
          ))}
        </MapContainer>
        :
        <div className='text-4xl flex items-center justify-center'>
          <div>
            <Loader />
          </div>
        </div>
      }
      {currentMapLoading && <LoadMap />}
      <Modal open={share} footer={null} onCancel={() => setShare(!share)} title={<div className='sm:text-2xl text-base text-center text-red-500'>Share the map with your friends</div>}>
        <div className='w-full flex flex-col items-center justify-center'>
          <QRCode
            errorLevel="H"
            value={`https://massivebaseprotection.com/${currentMap}`}
            icon={logo}
            className='my-5'
          />
          <Paragraph copyable className='bg-white text-sm text-black rounded-sm py-2 px-3 my-2' >
            {`https://massivebaseprotection.com/${currentMap}`}
          </Paragraph>
        </div>
      </Modal>
    </div>
  );
};

export default Map;