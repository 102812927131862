import { BrowserRouter, Route, Routes } from "react-router-dom";
import MyMap from "./Pages/Map";
import Home from "./Pages/Home";
import Help from "./Pages/Help";
import Navbar from "./component/Navbar";
import SignIn from "./Pages/SignIn";
import SignUp from "./Pages/SignUp";
import {jwtDecode} from "jwt-decode";
import store from "./redux/store";
import { SetUserData } from "./redux/authSlice";
import Manager from "./Pages/Manager";
import { useEffect, useState } from "react";
import NotFound from "./Pages/NotFound";
import ShareMap from "./Pages/ShareMap";
import Footer from "./component/Footer";

if (localStorage.getItem('token')) {
  let token = localStorage.getItem('token');
  let temp = jwtDecode(token);    
  store.dispatch(SetUserData(temp));
}


const App = () => {
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const loadResources = async () => {
      for (let i = 0; i <= 100; i++) {
        setProgress(i);
        await new Promise(resolve => setTimeout(resolve, 30));
      }
      setLoading(false);
    };

    loadResources();
  }, []);
  if (loading) {
    return(
      <div className="flex flex-col items-center justify-center h-screen w-screen">
        <div className="w-20 h-20 border-4 border-transparent text-blue-400 text-4xl animate-spin flex items-center justify-center border-t-blue-400 rounded-full">
          <div className="w-16 h-16 border-4 border-transparent text-red-400 text-2xl animate-spin flex items-center justify-center border-t-red-400 rounded-full" />
        </div>
        <div className="my-5">Loading... {progress}%</div>
      </div>
    )
  }
  return(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/map" element={<MyMap />} />
        <Route path="/help" element={<Help />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/manager" element={<Manager />} />
        <Route path="/:id" element={<ShareMap />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Navbar />
      <Footer />
    </BrowserRouter>
  )
}

export default App;