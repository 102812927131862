import { Carousel } from "antd";
// import {
//   MenuOutlined
// } from '@ant-design/icons';
import { Link } from "react-router-dom";
import PlayBtn from "../component/PlayBtn";
import Header from "../component/Header";

const Home = () => {
    return(
        <>
            <Header />
            <div className="w-full min-h-screen">
                <Carousel autoplay autoplaySpeed={2500} className="relative">
                    <div className="flex min-h-screen bg-[url(./assets/bg1.jpg)] bg-cover bg-no-repeat bg-slate-500"></div>
                    <div className="flex min-h-screen bg-[url(./assets/bg2.jpg)] bg-cover bg-no-repeat bg-slate-500"></div>
                    <div className="flex min-h-screen bg-[url(./assets/bg3.jpg)] bg-cover bg-no-repeat bg-slate-500"></div>
                    <div className="flex min-h-screen bg-[url(./assets/bg4.png)] bg-cover bg-no-repeat bg-slate-500"></div>
                </Carousel>
                <div className="absolute left-1/2 xl:top-3/4 sm:top-1/2 top-1/3 -translate-x-1/2 ">
                    <Link to={"/signin"}>
                        <PlayBtn />
                    </Link>
                </div>
            </div>
        </>
    )
}

export default Home;